import customers from '../img/icon-customers.svg';
import saveCustomerInfo from '../img/icon-save-customer-info.svg';
import betterReporting from '../img/icon-better-reporting.svg';
import saveTime from '../img/icon-save-time.svg';
import customersImage from '../img/CustomersImage.png';
import reportingCustomers from '../img/ReportsCustomers.png';
import terminalCustomers from '../img/TerminalCustomers.png';
import recurringBillingCustomer from '../img/RecurringBillingCustomers.png';
import Header from '../Header';
import MobileNavigation from '../MobileNavigation';
import background from '../img/softwarePartners.png';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import { useRef } from 'react';
import { useGSAP } from '@gsap/react';
import DrawSVGPlugin from '@gsap/business/DrawSVGPlugin';
import gsap from '@gsap/business';
import HeroFeatures from './HeroFeatures';
import GetStarted from './GetStarted';

gsap.registerPlugin(useGSAP);

gsap.registerPlugin(DrawSVGPlugin);

export default function Customers() {
  const container = useRef();
  useGSAP(
    () => {
      gsap.fromTo(
        '.hero-row',
        {
          y: 100,
          opacity: 0
        },
        { y: 0, opacity: 1, ease: 'power2.inOut', duration: 1 }
      );
      gsap.fromTo(
        '.card',
        { y: 100, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          ease: 'power2.inOut',
          duration: 1,
          stagger: 0.1,
          scrollTrigger: {
            trigger: '.card',
            start: 'top bottom-=200',
            end: 'top bottom+=100'
          }
        }
      );
      let maintain = document.querySelector('.maintain').children;
      gsap.fromTo(
        maintain,
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          ease: 'power2.inOut',
          duration: 1,
          stagger: 0.1,
          scrollTrigger: { trigger: '.maintain', start: 'top bottom-=200', end: 'top bottom+=100' }
        }
      );
      gsap.fromTo(
        '.how',
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          ease: 'power2.inOut',
          duration: 1,
          scrollTrigger: { trigger: '.how', start: 'top bottom-=200', end: 'top bottom+=100' }
        }
      );
      gsap.fromTo(
        '.callout',
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          ease: 'power2.inOut',
          duration: 1,
          scrollTrigger: { trigger: '.callout', start: 'top bottom-=200', end: 'top bottom+=100' }
        }
      );
      gsap.fromTo(
        '.reports',
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          ease: 'power2.inOut',
          duration: 1,
          scrollTrigger: { trigger: '.reports', start: 'top bottom-=200', end: 'top bottom+=100' }
        }
      );
      gsap.fromTo(
        '.terminalCust',
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          ease: 'power2.inOut',
          duration: 1,
          scrollTrigger: {
            trigger: '.terminalCust',
            start: 'top bottom-=200',
            end: 'top bottom+=100'
          }
        }
      );
      gsap.fromTo(
        '.getStartedCust',
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          ease: 'power2.inOut',
          duration: 1,
          scrollTrigger: {
            trigger: '.getStartedCust',
            start: 'top bottom-=200',
            end: 'top bottom+=100'
          }
        }
      );
      gsap.fromTo(
        '.recur',
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          ease: 'power2.inOut',
          duration: 1,
          scrollTrigger: { trigger: '.recur', start: 'top bottom-=200', end: 'top bottom+=100' }
        }
      );
    },
    { scope: container }
  );
  return (
    <>
      <main ref={container}>
        <Header />
        <MobileNavigation />

        <HeroFeatures
          title="CUSTOMERS"
          primary={
            <>
              Process payments <span className="highlight-pink">quickly</span> with Customers.
            </>
          }
          secondary="Securely store and manage your customers' payment details."
          img={customersImage}
          imgAlt="Customer details screenshot"
        />
        <section className="">
          <div className="grid grid-cols-1 gap-4 row sm:grid-cols-2 lg:grid-cols-4">
            <article className="card">
              <figure>
                <img src={customers} alt="lock icon" />
              </figure>
              <p className="light">Securly store payment information</p>
            </article>
            <article className="card">
              <figure>
                <img src={saveCustomerInfo} alt="customer icon" />
              </figure>
              <p className="light">Easily save customer info from multiple channels</p>
            </article>
            <article className="card">
              <figure>
                <img src={betterReporting} alt="paper icon" />
              </figure>
              <p className="light">Customize fields for better reporting</p>
            </article>
            <article className="card">
              <figure>
                <img src={saveTime} alt="time icon" />
              </figure>
              <p className="light">Save time and streamline transaction processes</p>
            </article>
          </div>
        </section>
        <section className="">
          <div className="row maintain">
            <div className="grid justify-center grid-cols-1">
              <p className="medium">
                Maintaining a PCI-compliant payment gateway is complicated. Let us help make it
                simple.
              </p>
            </div>
            <div className="grid grid-cols-1">
              <p className="light">
                By limiting the amount of sensitive data in your system, saving Customers mitigates
                your risk and helps avoid the cost and resources it takes to meet PCI requirements.
              </p>
            </div>
          </div>
        </section>
        <section className="">
          <div
            className="row"
            style={{
              borderRadius: '25px',
              backgroundImage: `url(${background})`,
              backgroundSize: '200% 200%',
              backgroundPosition: 'bottom center',
              backgroundRepeat: 'no-repeat'
            }}
          >
            <div className="p-4 mx-auto md:p-8 how">
              <h3 className="text-white large">How do Customers work?</h3>
              <p className="text-white light">Here's how you can save customer data:</p>
              <ul className="my-8 fancy-bullets white">
                <li>
                  <p className="text-white light">
                    Add a Customer manually under the Customers tab in the iQ Pro+ gateway. Enter
                    their payment and contact information with peace of mind that their data is
                    secure thanks to advanced tokenization.
                  </p>
                </li>
                <li>
                  <p className="text-white light">
                    If you’re using the Terminal to process a transaction with a recurring customer,
                    select “Save Customer” before submitting the transaction. This action saves your
                    customer’s details automatically.
                  </p>
                </li>
              </ul>
              <p className="text-white light">
                Forgot to check the "Save Customer" box before submitting a transaction? You can
                save Customer details from past transactions, too.
              </p>
            </div>
          </div>
        </section>
        <section className="">
          <div className="row">
            <div className="callout">
              <p className="large">
                Your Customers matter - managing their details should be{' '}
                <span className="highlight-pink">stress free</span>.
              </p>
              <p className="medium light">
                iQ Pro+ offers advanced filtering so you can find your Customers quickly. Save
                custom search filters to access records in one click or locate a saved Customer
                directly from the Terminal payment screen.
              </p>
            </div>
          </div>
        </section>
        <section className="">
          <div className="grid items-center grid-cols-1 row lg:grid-cols-2 reports">
            <article className="col-span-1">
              <p className="text-center large lg:text-left">
                Create custom <span className="highlight-pink">Reports</span> using personalized
                Customer details.
              </p>
              <Link
                to="/features/reporting"
                className="button button-primary has-arrow has-arrow-white center-mobile"
              >
                Learn More
              </Link>
            </article>
            <figure className="col-span-1">
              <img
                src={reportingCustomers}
                alt="page rendering on what reporting looks like with customers"
              />
            </figure>
          </div>
        </section>
        <section className="">
          <div className="grid items-center grid-cols-1 row lg:grid-cols-2 terminalCust">
            <figure className="hidden col-span-1 lg:block">
              <img src={terminalCustomers} alt="terminal screenshot in respect to customers" />
            </figure>
            <article className="col-span-1 text-center lg:text-left">
              <p className="large">
                Select Customers directly from <span className="highlight-pink">Terminal</span>{' '}
                while processing transactions.
              </p>
              <Link
                to="/features/terminal"
                className="button button-primary has-arrow has-arrow-white center-mobile"
              >
                Learn More
              </Link>
            </article>
            <figure className="col-span-1 lg:hidden">
              <img src={terminalCustomers} alt="terminal screenshot in respect to customers" />
            </figure>
          </div>
        </section>
        <section className="">
          <div className="grid items-center grid-cols-1 row lg:grid-cols-2 recur">
            <article className="col-span-1">
              <p className="text-center large lg:text-left">
                Easily add saved customers to subscriptions utilizing our{' '}
                <span className="highlight-pink">Recurring Billing</span> feature.
              </p>
              <Link
                to="/features/recurringBilling"
                className="button button-primary has-arrow has-arrow-white center-mobile"
              >
                Learn More
              </Link>
            </article>
            <figure className="col-span-1">
              <img
                src={recurringBillingCustomer}
                alt="recurring billing screenshot with respect to customers"
              />
            </figure>
          </div>
        </section>
        <GetStarted classGet="getStartedCust" />
      </main>
      <Footer />
    </>
  );
}
