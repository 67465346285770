import { Link } from 'react-router-dom';
export default function GetStarted({ classGet }) {
  return (
    <section className={classGet}>
      <div className="grid items-center justify-center grid-cols-1 row row-sm">
        <div>
          <p className="text-center large">Ready to get started?</p>
          <Link to="/bookDemo" className="button button-primary center has-arrow has-arrow-white">
            Book a demo today!
          </Link>
        </div>
      </div>
    </section>
  );
}
