import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import Navbar from './Navbar';
import MobileNavigation from './MobileNavigation';
import Footer from './Footer';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { styled } from '@mui/material/styles';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    variant="scrollable"
    scrollButtons
    allowScrollButtonsMobile
    TabIndicatorProps={{
      children: <span className="MuiTabs-indicatorSpan" />
    }}
  />
))(({ theme }) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent'
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: theme.palette.secondary.main
  }
}));

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: theme.palette.fontColor.main,
  '&.Mui-selected': {
    color: '#1C7BC0',
    fontWeight: theme.typography.fontWeightMedium
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)'
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function TabProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const GeneralFAQ = [
  {
    question: 'How do I login to iQ Pro+?',
    answer: [
      'You can login into iQ Pro+ ',
      <a key={'key'} href="https://app.iqpro.com/" className="links">
        here.
      </a>
    ]
  },
  {
    question: 'How do I reset my password?',
    answer: [
      'If you have forgotten your login credentials, please utilize the “Forgot your password?” links on the ',
      <a key={'key'} href="https://app.iqpro.com/" className="links">
        login page.
      </a>
    ]
  },
  {
    question: 'What transaction types does iQ Pro+ Support?',
    answer: [
      'iQ Pro+ supports Sales, Pre-Authorizations, Card Verifications, Full and Partial Refunds, and Blind Credits.'
    ]
  },
  {
    question: 'Why did my transaction decline?',
    answer: [
      [
        'Transactions are approved or declined by the issuing bank of the card. iQ Pro+ will display the full, detailed transaction response that includes the decline code form the issuer. Please ',
        <a
          key={'key'}
          href="tel:8883661325"
          aria-label="Contact us at 8 8 8 3 6 6 1 3 2 5"
          className="links"
        >
          contact us
        </a>,
        ' if you need more info on a transaction’s status.'
      ]
    ]
  },
  {
    question: 'What fields are required to process Level II / Level III?',
    answer: [
      '    The minimum required fields to process a card transaction include the payment information (card number and expiration date) and the cardholder first and last name. All Level II and III fields are submitted automatically on qualified card types without manual entry. '
    ]
  },
  {
    question: 'How can I export my transactions for reporting?',
    answer: [
      'iQ Pro+ supports export of transaction Reporting through the universal format of a CSV file for easy upload into other software for reporting and reconciliation purposes or downloadable PDF.'
    ]
  }
];

const ConversionFAQ = [
  {
    question: 'Why is our business asked to convert to iQ Pro+? ',
    answer:
      'iQ Pro+ was developed by the same team who brought you iQ Pro! With an updated user interface as well as many new and improved features, iQ Pro is the future of virtual terminals for your business. To continue offering new features, product improvements, and the best processing experience possible, all existing iQ Pro accounts are transitioning to our new virtual terminal - iQ Pro+!'
  },
  {
    question: 'Will we have to manually move our records to iQ Pro+?',
    answer:
      'No. As a part of this conversion to iQ Pro+, prior to your training, our team will migrate all your customer vault records and transaction history to your new iQ Pro+ Gateway. The Data Migration will take place the night before your scheduled iQ Pro Training. '
  },
  {
    question: 'What training options will be available?',
    answer: (
      <ul>
        <li>
          Self-Guided Training Videos- This training allows you to navigate through a series of
          training videos surrounding the features and functionality of iQ Pro+. If additional
          assistance is needed, the BASYS Customer Support Team is available to answer questions and
          can connect your business with our iQ Pro+ Training Team as needed.
        </li>
        <li>
          Phone training with an iQ Pro+ Trainer- If you or your teammates have questions after the
          self-guided training or if you need additional training, our Customer Support team is
          available to you from 7am-7pm CT at (888) 366-1325.{' '}
        </li>
      </ul>
    )
  },
  {
    question: 'What are the benefits of iQ Pro+?',
    answer: (
      <ul>
        <li>Enhanced security & Increased efficiency</li>
        <li>
          Simplified sign-in flow using your email address rather than relying on a separate
          username
        </li>
        <li>Customer lookup embedded in the virtual terminal</li>
        <li>
          Quick Transaction Reports with quick report filters, the ability to download as CSV and
          PDF file types, as well as advanced search options
        </li>
        <li>
          User-friendly settlement reporting, including the ability to download as CSV and PDF file
          types, settlement totals and transaction totals
        </li>
        <li>Multi-location functionality</li>
      </ul>
    )
  },
  {
    question: 'Will we need a new card swiper to use with iQ Pro+?',
    answer:
      'No, all Magtek swipers currently being utilized alongside iQ Pro will also work with iQ Pro+.'
  },
  {
    question: 'Will iQ Pro+ cost more than iQ Pro?',
    answer: 'No, there will be no additional costs to use iQ Pro+'
  },
  {
    question: "What if our business doesn't like iQ Pro+? Can we still use iQ Pro?",
    answer:
      'We are confident that iQ Pro+ will exceed all expectations and will be a value add for your business. If for some reason you are not happy with the product, please contact us with your concerns. Once your business has received your credentials and training on iQ Pro+, your old iQ Pro portal will be deactivated.'
  },
  {
    question: 'Is converting to iQ Pro+ required?',
    answer: 'Yes, all eligible iQ Pro accounts will convert to iQ Pro+.'
  }
];

const InvoicesFAQ = [
  {
    question: 'What payment methods are supported?',
    answer: ['Customers can currently pay invoices by credit card, or secure bank payment (ACH).']
  },
  {
    question: 'Can you manage a gateway user’s access to Invoices?',
    answer: [
      'Yes, gateway admins can manage access to the invoicing functionality through four User Permissions: Create, Read, Delete, and View All Invoices. If a user does not have any of these permissions, the Invoices module will be hidden from their navigation menu. You can adjust these settings by going to Settings > Users > Edit User > Permissions.'
    ]
  },
  {
    question: 'Can you edit an invoice?',
    answer: [
      'Invoices with a Draft status can be edited at any time. Invoices in a Sent or Overdue status without any payments can also be edited; however, doing so will cancel the original invoice and generate a new one. Invoices that are Paid or Partially Paid cannot be edited, maintaining the integrity of transaction records. '
    ]
  },
  {
    question: 'Why can’t you delete or cancel some invoices?',
    answer: [
      'Only Invoices in a Draft status can be deleted. If an invoice was created by mistake, you can cancel it to remove it from your list of active invoices before any payment is made. Only Invoices in a Sent or Overdue status with no payment(s) can be cancelled.'
    ]
  },
  {
    question: 'Can you create and send invoices in bulk?',
    answer: [
      'iQ Pro+ does not currently support bulk invoicing. However, existing invoices may be cloned, which simplifies the duplication of common invoices by automatically populating the invoice with information from the cloned source invoice. To clone an invoice, navigate to Invoices > Actions > Clone. '
    ]
  },
  {
    question: 'Can you use your own Invoice Id or Number?',
    answer: [
      'The Invoice Id is system generated and cannot be changed. You can enter your own identifier in the Invoice Number field when creating an invoice. The Invoice Number will display on email notifications, the payment page, and receipts.'
    ]
  },
  {
    question: 'Can you create recurring invoices?',
    answer: (
      <div>
        Yes! Recurring invoices are managed through Subscriptions. To learn more, visit the
        Subscriptions feature page{' '}
        <a href="https://iqpro.com/features/recurringBilling" className="links">
          here
        </a>
        .
      </div>
    )
  },
  {
    question: "How can you view a payment that's been made on an invoice?",
    answer: (
      <div>
        There are several ways to find your customer’s invoice payments.
        <ol>
          <li>
            From the Invoices menu, select Actions {'>'} View transaction(s), or click the Invoice
            Id to open the invoice and select View Transaction History.
          </li>
          <li>
            Search the Transactions List directly by selecting Reporting {'>'} Transactions, and
            filtering by the Invoice Id.
          </li>
          <li>
            If the invoice is linked to a vaulted customer, the transaction will be visible within
            the Transactions tab of the customer&apos;s record (View Customer {'>'}
            Transactions).
          </li>
        </ol>
        The Transaction Details will display the Invoice Id linked back to the Invoice Details,
        making it easy to review or manage further actions.
      </div>
    )
  }
];

const SimplePayFAQ = [
  {
    question: 'What payment methods are supported?',
    answer: ['Customers can currently pay by credit card, or secure bank payment (ACH).']
  },
  {
    question: 'Can you manage a gateway user’s access to Simple Pay?',
    answer: [
      'Yes, gateway admins can manage access to the Simple Pay functionality through five User Permissions: Create, Read, Edit, Delete, and View All Simple Pay. If a user does not have any of these permissions, the Simple Pay module will be hidden from their navigation menu. You can adjust these settings by going to Settings > Users > Edit User > Permissions.'
    ]
  },
  {
    question: 'What happens if I delete a Simple Pay page? Can customers still access it?',
    answer: ['Once a page is deleted, an error message will display when the URL is accessed.']
  },
  {
    question: 'How can I make sure customers receive a receipt for their transaction?',
    answer: [
      'Customers can check “Email a copy of this receipt” and enter their email address to receive an emailed receipt. The receipt can also be resent at any time from Reporting > Transactions.'
    ]
  },
  {
    question: 'Can I have multiple Simple Pay pages?',
    answer: [
      'You can! For example, you can have a page that accepts only card payments and another page that accepts card and ACH payments.'
    ]
  },
  {
    question: 'Can I add my own logo and colors?',
    answer: [
      'Yes, the file format must be .jpg or .png; you can also select up to three colors to customize your page.'
    ]
  }
];

const MobileAppFAQ = [
  {
    question: 'Who can use the iQ Pro mobile app?',
    answer: [
      'Users who have been onboarded onto the iQ Pro+ app will be able to access the iQ Pro mobile app. Your login will remain the same across both iQ Pro+ and the iQ Pro mobile app.'
    ]
  },
  {
    question: ['Who can use ', <i>Quick Pay</i>, ' on the mobile app?'],
    answer: [
      'Anybody with the iQPro Plus mobile app can use the Quick Pay feature with the following restrictions: ',
      <ul className="ml-8 list-disc quick-pay">
        <li className="">
          You can only process transactions with the type of sale and the payment method of credit
          card.
        </li>
        <li className="">
          You must have a default credit card processor configured at the gateway level.
        </li>
        <li className="">
          The tax and payment adjustment values configured for the gateway will automatically
          applied to the transaction. i.e. an 8.75% tax rate and a 3% payment adjustment will be
          calculated based on the base amount and applied to the transaction.
        </li>
      </ul>
    ]
  },
  {
    question: 'How do I download the iQ Pro mobile app?',
    answer: [
      'You can find a guide on downloading the mobile app ',
      <a
        key={'key'}
        href="https://basyspro.com/wp-content/uploads/2024/07/iQPro_MobileApp-Download-Guide.pdf"
        className="links"
      >
        here.
      </a>
    ]
  },
  {
    question: 'Are the settings for the gateway in iQ Pro+ carried over to the iQ Pro mobile app?',
    answer: [
      'Yes! All data is shared between the two applications. The settings configured for the gateway in iQ Pro+ are mirrored on the iQ Pro mobile app.'
    ]
  },
  {
    question:
      'Will I be able to see transactions and customers from iQ Pro+ in the mobile app and vice versa?',
    answer: [
      'Yes! All processed transactions and vaulted customers are visible across the iQ Pro mobile app and iQ Pro+.'
    ]
  },
  {
    question: 'Which transaction types can be processed on the mobile app?',
    answer: [
      'The iQ Pro mobile app supports Sales, Pre-Authorizations and Card Verifications. Users can also Void transactions and issue Full Refunds. Partial Refunds and Blind Credits must be applied through iQ Pro+.'
    ]
  },
  {
    question: 'How are customer receipts generated and distributed in the mobile app?',
    answer: (
      <div>
        Select the checkbox marked <b>Send Receipt</b> when processing a transaction and enter the
        customer’s email address. Once the transaction is processed, an email will be sent to the
        address entered.
      </div>
    )
  },
  {
    question: 'Can users access multiple gateways through the mobile app?',
    answer: (
      <div>
        Yes! If a login is associated with multiple gateways, all gateways are available and
        accessible through the mobile app. Simply select the desired gateway upon logging in. Switch
        between gateways by clicking the settings icon in the upper right corner of the home page,
        select the gateway and click <b>Switch Gateway</b>.
      </div>
    )
  }
];

const OnboardingConversion = () => {
  const [value, setValue] = useState(0);
  const handleChange = (eventt, newValue) => {
    setValue(newValue);
  };
  return (
    <Grid>
      <Navbar />
      <MobileNavigation />
      {/* TITLE */}
      <Grid align="center">
        <Typography variant="h1" fontWeight="500" color="#1C7BC0" className="onboarding-title">
          Frequently Asked Questions
        </Typography>
      </Grid>
      {/* TABS */}
      <Box
        display="flex"
        justifyContent="center"
        width="100%"
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        <StyledTabs value={value} onChange={handleChange} aria-label="FAQ tabs" centered>
          <StyledTab label="General" {...TabProps(0)} />
          <StyledTab label="Conversion" {...TabProps(1)} />
          <StyledTab label="Invoicing" {...TabProps(2)} />
          <StyledTab label="Simple Pay" {...TabProps(3)} />
          <StyledTab label="Mobile App" {...TabProps(4)} />
        </StyledTabs>
      </Box>
      {/* GENERAL */}
      <TabPanel value={value} index={0}>
        <Grid container justifyContent="center">
          <Grid item xs={10} md={8}>
            <Accordion allowZeroExpanded>
              {GeneralFAQ.map(({ index, question, answer }) => (
                <div key={index}>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>{question}</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>{answer}</AccordionItemPanel>
                  </AccordionItem>
                </div>
              ))}
            </Accordion>
          </Grid>
        </Grid>
      </TabPanel>
      {/* CONVERSION */}
      <TabPanel value={value} index={1}>
        <Grid container justifyContent="center">
          <Grid item xs={10} md={8}>
            <Accordion allowZeroExpanded>
              {ConversionFAQ.map(({ index, question, answer }) => (
                <div key={index}>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>{question}</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>{answer}</AccordionItemPanel>
                  </AccordionItem>
                </div>
              ))}
            </Accordion>
          </Grid>
        </Grid>
      </TabPanel>
      {/* INVOICING */}
      <TabPanel value={value} index={2}>
        <Grid container justifyContent="center">
          <Grid item xs={10} md={8}>
            <Accordion allowZeroExpanded>
              {InvoicesFAQ.map(({ index, question, answer }) => (
                <div key={index}>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>{question}</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>{answer}</AccordionItemPanel>
                  </AccordionItem>
                </div>
              ))}
            </Accordion>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Grid container justifyContent="center">
          <Grid item xs={10} md={8}>
            <Accordion allowZeroExpanded>
              {SimplePayFAQ.map(({ index, question, answer }) => (
                <div key={index}>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>{question}</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>{answer}</AccordionItemPanel>
                  </AccordionItem>
                </div>
              ))}
            </Accordion>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Grid container justifyContent="center">
          <Grid item xs={10} md={8}>
            <Accordion allowZeroExpanded>
              {MobileAppFAQ.map(({ index, question, answer }) => (
                <div key={index}>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>{question}</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>{answer}</AccordionItemPanel>
                  </AccordionItem>
                </div>
              ))}
            </Accordion>
          </Grid>
        </Grid>
      </TabPanel>
      <Footer />
    </Grid>
  );
};

export default OnboardingConversion;
