import logo from './img/iQPro+ Logo.png';
import { useRef } from 'react';
import { useGSAP } from '@gsap/react';
import gsap from '@gsap/business';
import DrawSVGPlugin from '@gsap/business/DrawSVGPlugin';
import { Link } from 'react-router-dom';

gsap.registerPlugin(useGSAP);

gsap.registerPlugin(DrawSVGPlugin);

export default function Header() {
  const container = useRef();
  useGSAP(
    () => {
      let featureMenu = gsap.fromTo(
        '.dropdown-features',
        {
          opacity: 0,
          scale: 0
        },
        { opacity: 1, scale: 1, duration: 0.2, paused: true, transformOrigin: 'center top' }
      );
      let resourcesMenu = gsap.fromTo(
        '.dropdown-resources',
        {
          opacity: 0,
          scale: 0
        },
        { opacity: 1, scale: 1, duration: 0.2, paused: true, transformOrigin: 'left top' }
      );
      let loginMenu = gsap.fromTo(
        '.dropdown-login',
        {
          opacity: 0,
          scale: 0
        },
        { opacity: 1, scale: 1, duration: 0.2, paused: true, transformOrigin: 'left top' }
      );
      let featuresButton = document.getElementById('featuresButton');
      let dropdown = document.getElementById('features');
      let resourcesButton = document.getElementById('resourcesButton');
      let dropdown2 = document.getElementById('resources');
      let loginButton = document.getElementById('loginButton');
      let dropdown3 = document.getElementById('login');
      featuresButton.addEventListener('mouseenter', () => featureMenu.play());
      dropdown.addEventListener('mouseleave', () => featureMenu.reverse());
      // resourcesButton.addEventListener('mouseenter', () => resourcesMenu.play());
      // dropdown2.addEventListener('mouseleave', () => resourcesMenu.reverse());
      loginButton.addEventListener('mouseenter', () => loginMenu.play());
      dropdown3.addEventListener('mouseleave', () => loginMenu.reverse());
    },
    { scope: container }
  );
  return (
    <section className="max-[1000px]:hidden">
      <div className="row nav-row">
        <header
          ref={container}
          className="flex self-stretch justify-between items-center px-2 py-2 rounded-[999px] bg-white box-shadow"
        >
          <a className="logo" href="/">
            <img src={logo} alt="iQ Pro+ Logo" />
          </a>
          <nav className={`flex items-center justify-center h-auto gap-0`}>
            <div className="dropdown" id="features">
              <button className="button button-secondary has-arrow" id="featuresButton">
                Features
              </button>
              <div className="grid grid-cols-1 lg:grid-cols-2 dropdown-content dropdown-features">
                <Link to="/features/terminal" className="subnav-item">
                  <p className="text-black sub-nav-header has-icon terminal !mb-3">Terminal</p>
                  <p className="text-black">
                    Securely take payments on any device with an internet connection.
                  </p>
                </Link>
                <Link to="/features/customers" className="subnav-item">
                  <p className="text-black sub-nav-header has-icon customers !mb-3">Customers</p>
                  <p className="text-black">
                    Securely store and manage your customers payment details.
                  </p>
                </Link>

                <Link to="/features/simplePay" className="subnav-item">
                  <p className="text-black sub-nav-header has-icon simple-pay !mb-3">Simple Pay</p>
                  <p className="text-black">
                    Customize and send your customers a pre-built payment form.
                  </p>
                </Link>
                <Link to="/features/invoicing" className="subnav-item">
                  <p className="text-black sub-nav-header has-icon invoices !mb-3">Invoicing</p>
                  <p className="text-black">
                    Create, send, and track payments effortlessly, all from one convenient platform.
                  </p>
                </Link>

                <Link to="/features/recurringBilling" className="subnav-item">
                  <p className="text-black sub-nav-header has-icon recurring-billing !mb-3">
                    Recurring Billing
                  </p>
                  <p className="text-black">
                    Recurring payments that simplify the billing process.
                  </p>
                </Link>
                <Link to="/features/mobileApp" className="subnav-item">
                  <p className="text-black sub-nav-header has-icon mobile-app !mb-3">Mobile App</p>
                  <p className="text-black">Process payments from your phone or tablet.</p>
                </Link>

                <Link to="/features/reporting" className="subnav-item">
                  <p className="text-black sub-nav-header has-icon reporting !mb-3">Reporting</p>
                  <p className="text-black">
                    View, reconcile, and analyze transaction and settlement data.
                  </p>
                </Link>
                <Link to="/features/physicalTerminals" className="subnav-item">
                  <p className="text-black sub-nav-header has-icon physical-terminals !mb-3">
                    Physical Terminals
                  </p>
                  <p className="text-black">
                    Process card transactions wherever your business takes you.
                  </p>
                </Link>
              </div>
            </div>
                <a
                  className="button button-secondary has-arrow"
                  href="https://developer.basyspro.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                    Developer
                </a>

            <Link to="/support" className="button button-secondary has-arrow">
              Support
            </Link>
            <div className="dropdown" id="login">
              <button className="button button-secondary has-arrow" id="loginButton">
                Login
              </button>
              <div className="grid grid-cols-1 dropdown-content dropdown-login">
                <a
                  className="subnav-item"
                  href="https://basyspro.net/Default.aspx"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="text-black sub-nav-header">Basys IQ</p>
                </a>
                <a
                  className="subnav-item"
                  href="https://app.basysiqpro.com/auth"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="text-black sub-nav-header">iQ Pro</p>
                </a>
                <a
                  className="subnav-item"
                  href="https://app.iqpro.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="text-black sub-nav-header">iQ Pro+</p>
                </a>
              </div>
            </div>
          </nav>
          <Link
            to="/bookDemo"
            className="button button-primary has-arrow has-arrow-white"
            id="navTopButtonNormal"
          >
            Contact Sales
          </Link>
        </header>
      </div>
    </section>
  );
}
