import Footer from './Footer';
import logo from './img/iQPro+ Logo.png';
import whiteLogo from './img/WhiteLogo.svg';
import { useRef, useState } from 'react';
import { Select, Input, InputNumber, Form, Alert } from 'antd';
import axios from 'axios';
import { Link } from 'react-router-dom';
import background from './img/bookDemoTextBackground.png';
import gsap from '@gsap/business';
import { ScrollTrigger } from '@gsap/business/ScrollTrigger';
import { useGSAP } from '@gsap/react';
gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

export default function BookDemo() {
  const container = useRef();
  const [form] = Form.useForm();
  const [alert, throwAlert] = useState(<></>);
  const [helpText, setHelpText] = useState(
    'I’m new to Basys. I want to learn about accepting cards with iQ Pro+.'
  );
  const handleChange = (value) => {
    if (value === 'New Merchant') {
      setHelpText('I’m new to Basys. I want to learn about accepting cards with iQ Pro+.');
    } else if (value === 'Current Merchant') {
      setHelpText('I’m a current Basys customer and I want to learn more about iQ Pro+.');
    } else if (value === 'Integrations Partner') {
      setHelpText('I want to learn about integrating iQ Pro+ with my company’s software.');
    } else {
      setHelpText(' ');
    }
  };

  const [validateStatusPhone, setValidateStatusPhone] = useState('');
  const [helpTextPhone, setHelpTextPhone] = useState(
    'Please add a 10 digit phone number, do not enter dashes'
  );
  const onChangePhone = () => {
    const value = String(document.getElementById('pnumber').value);

    const result = /^\d{10}$/.test(value);
    if (result) {
      setValidateStatusPhone('success');
      setHelpTextPhone('');
      document.getElementById('submitRequest').disabled = false;
    } else {
      setValidateStatusPhone('error');
      setHelpTextPhone('Please add a 10 digit phone number, do not enter dashes');
    }
  };
  const onFinish = (values) => {
    document.getElementById('submitRequest').disabled = true;
    console.log(values);
    const value = String(document.getElementById('pnumber').value);
    const result = /^\d{10}$/.test(value);
    if (!result) {
      throw new Error('The phone number is not 10 digits');
    }
    setValidateStatusPhone('');
    setHelpTextPhone('');
    console.log('Email sending...');
    let date = new Date();
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    const userDate = date.toISOString().split('T')[0];
    values.date = userDate;
    axios
      .post(`${process.env.REACT_APP_EMAIL_FUNCTION_URL}/api/sendleademail`, values)
      .then((res) => {
        if (res.status === 200) {
          console.log('success');
          throwAlert(
            <Alert
              message="Thank you for reaching out."
              description={
                <>
                  <p>Your request will be sent to one of our representatives.</p>
                  <br />
                  <Link to="/" className="flex items-center justify-center px-4 py-2">
                    <p className="font-customFont text-black text-[20px] font-medium underline">
                      Return to Home
                    </p>
                  </Link>
                </>
              }
              type="success"
              showIcon
              closable
            />
          );
          form.resetFields();
        }
      })
      .catch(function (error) {
        console.error(error);
        document.getElementById('submitRequest').disabled = false;
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  useGSAP(
    () => {
      const tl = gsap.timeline();
      tl.fromTo(
        '.book-title',
        { opacity: 0, y: 100 },
        { opacity: 1, y: 0, duration: 1, ease: 'power2.in' }
      );
      tl.fromTo(
        '.book',
        { opacity: 0, y: 100 },
        { opacity: 1, y: 0, duration: 1, ease: 'power2.in' },
        '-=0.5'
      );
    },
    { scope: container }
  );
  return (
    <>
      <main ref={container}>
        <section className="">
          <div className="grid grid-cols-1 row">
            <a className="mb-8 logo dark:hidden" id="logo" href="/">
              <img src={logo} alt="iQ Pro+ Logo" />
            </a>
            <a className="hidden mb-8 logo dark:block" id="logo" href="/">
              <img src={whiteLogo} alt="iQ Pro+ Logo" />
            </a>
            <h1 className="dark:text-white book-title">We’d love to work with you.</h1>
          </div>
        </section>
        <section className="book">
          <div
            className="grid grid-cols-1 row md:grid-cols-2 md:gap-14 justify-items-stretch"
            style={{ padding: '0 1.5rem' }}
          >
            <section
              className="p-12 mb-8 lg:p-16"
              style={{
                borderRadius: '25px',
                backgroundImage: `url(${background})`,
                backgroundSize: '200% 200%',
                backgroundPosition: 'bottom center',
                backgroundRepeat: 'no-repeat'
              }}
            >
              <h2 className="text-white large">Book a Demo</h2>
              <p className="my-8 text-white slight">
                Whether you're a new merchant or currently processing with Basys, iQ Pro+ gives you
                the power to take your payments to the next level while saving time, money and
                hassle.
              </p>
              <p className="text-white">Focus on your business and we’ll handle the rest.</p>
            </section>
            <Form
              form={form}
              id="form"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              initialValues={{ leadType: 'New Merchant' }}
            >
              <div className="form-item">
                <p className="large">I am a(n)...</p>
                <label htmlFor="role">Select an option</label>
                <Form.Item name="leadType">
                  <Select
                    size="large"
                    id="role"
                    name="leadType"
                    onChange={handleChange}
                    options={[
                      {
                        value: 'New Merchant',
                        label: 'New Merchant'
                      },
                      {
                        value: 'Current Merchant',
                        label: 'Current Merchant'
                      },
                      {
                        value: 'Integrations Partner',
                        label: 'Integrations Partner'
                      }
                    ]}
                  />
                </Form.Item>
                <p className="mt-4 mb-4 small light">{helpText}</p>
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-8">
                <div className="form-item">
                  <label htmlFor="fname">First Name</label>
                  <Form.Item
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: 'Please add first name'
                      }
                    ]}
                  >
                    <Input id="fname" name="firstName" size="large" />
                  </Form.Item>
                </div>
                <div className="form-item">
                  <label htmlFor="lname">Last Name</label>

                  <Form.Item
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: 'Please add last name'
                      }
                    ]}
                  >
                    <Input id="lname" name="Last Name" size="large" />
                  </Form.Item>
                </div>
              </div>
              <div className="form-item">
                <label htmlFor="cname">Company Name</label>
                <Form.Item
                  name="companyName"
                  rules={[
                    {
                      required: true,
                      message: 'Please add company name'
                    }
                  ]}
                >
                  <Input id="cname" name="Company Name" size="large" />
                </Form.Item>
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-8">
                <div className="form-item">
                  <label htmlFor="email">Email</label>
                  <Form.Item
                    name="email"
                    validateTrigger="onBlur"
                    rules={[
                      {
                        type: 'email',
                        required: true,
                        message: 'Please add a valid email'
                      }
                    ]}
                  >
                    <Input id="email" name="email" size="large" />
                  </Form.Item>
                </div>
                <div className="form-item">
                  <label htmlFor="pnumber">Phone Number</label>

                  <Form.Item
                    name="phoneNumber"
                    validateStatus={validateStatusPhone}
                    hasFeedback
                    help={helpTextPhone}
                    rules={[
                      {
                        required: true,
                        message: 'Please add a phone number'
                      }
                    ]}
                  >
                    <InputNumber
                      id="pnumber"
                      name="phoneNumber"
                      size="large"
                      placeholder="##########"
                      className="w-full"
                      onBlur={onChangePhone}
                      maxLength={10}
                    />
                  </Form.Item>
                </div>
              </div>
              <Form.Item
                shouldUpdate
                className="self-end mb-0 justify-self-end max-[681px]:justify-self-center"
              >
                <button
                  type="submit"
                  id="submitRequest"
                  className="button button-primary-dark has-arrow has-arrow-white"
                >
                  Submit
                </button>
              </Form.Item>
            </Form>
          </div>
        </section>
        {alert}
      </main>
      <Footer />
    </>
  );
}
