import Footer from '../Footer';
import Header from '../Header';
import MobileNavigation from '../MobileNavigation';
import InvoicingImg from '../img/InvoicingImage.png';
import background from '../img/softwarePartners.png';
import RecurringInvoicing from '../img/RecurringBillingInvoicing.png';
import { Link } from 'react-router-dom';
import { useRef } from 'react';
import { useGSAP } from '@gsap/react';
import DrawSVGPlugin from '@gsap/business/DrawSVGPlugin';
import gsap from '@gsap/business';
import HeroFeatures from './HeroFeatures';
import GetStarted from './GetStarted';
import acceptCCAndACh from '../img/icon-credit-card-or-ach.svg';
import automaticReceipts from '../img/icon-automatic-payment-receipts.svg';
import sendViaEmail from '../img/icon-send-via-email.svg';
import fullyIntegrated from '../img/icon-fully-integrated.svg';

gsap.registerPlugin(useGSAP);

gsap.registerPlugin(DrawSVGPlugin);

export default function Invoicing() {
  const container = useRef();
  useGSAP(
    () => {
      gsap.fromTo(
        '.hero-row',
        { y: 100, opacity: 0 },
        { y: 0, opacity: 1, duration: 1, ease: 'power3.inOut' }
      );
      gsap.fromTo(
        '.card',
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          stagger: 0.1,
          ease: 'power3.inOut',
          scrollTrigger: {
            trigger: '.card',
            start: 'top bottom-=200',
            end: 'top bottom+=100'
          }
        }
      );
      gsap.fromTo(
        '.easy',
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'power3.inOut',
          scrollTrigger: {
            trigger: '.easy',
            start: 'top bottom-=200',
            end: 'top bottom+=100'
          }
        }
      );
      const how = gsap.utils.toArray('.how');
      how.forEach((item) => {
        gsap.fromTo(
          item.children,
          { opacity: 0, y: 100 },
          {
            opacity: 1,
            y: 0,
            ease: 'power3.inOut',
            duration: 0.5,
            stagger: 0.1,
            scrollTrigger: {
              trigger: item,
              start: 'top bottom-=200',
              end: 'top bottom+=100'
            }
          }
        );
      });
      gsap.fromTo(
        '.callout',
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          ease: 'power3.inOut',
          duration: 1,
          scrollTrigger: {
            trigger: '.callout',
            start: 'top bottom-=200',
            end: 'top bottom+=100'
          }
        }
      );
      const cust = document.querySelector('.customer-payables').children;
      gsap.fromTo(
        cust,
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          ease: 'power3.inOut',
          duration: 0.5,
          stagger: 0.1,
          scrollTrigger: {
            trigger: '.customer-payables',
            start: 'top bottom-=200',
            end: 'top bottom+=100'
          }
        }
      );
      gsap.fromTo(
        '.invoicingRecurring',
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          ease: 'power3.inOut',
          duration: 1,
          scrollTrigger: {
            trigger: '.invoicingRecurring',
            start: 'top bottom-=200',
            end: 'top bottom+=100'
          }
        }
      );
      gsap.fromTo(
        '.getStartedInvoicing',
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          ease: 'power3.inOut',
          duration: 1,
          scrollTrigger: {
            trigger: '.getStartedInvoicing',
            start: 'top bottom-=200',
            end: 'top bottom+=100'
          }
        }
      );
    },
    { scope: container }
  );
  return (
    <>
      <main ref={container}>
        <Header />
        <MobileNavigation />
        <HeroFeatures
          title="INVOICING"
          primary={
            <>
              Get paid <span className="highlight-pink">faster</span>.
            </>
          }
          secondary="Simplify invoicing with tools to create, send and track payments effortlessly - all
                from one convenient platform."
          img={InvoicingImg}
          imgAlt="Invoicing Screen"
        />
        <section className="">
          <div className="grid grid-cols-1 gap-4 row sm:grid-cols-2 lg:grid-cols-4">
            <article className="card">
              <figure>
                <img src={acceptCCAndACh} alt="card icon" />
              </figure>
              <p className="light">Accept Card and ACH payments</p>
            </article>
            <article className="card">
              <figure>
                <img src={automaticReceipts} alt="scroll icon" />
              </figure>
              <p className="light">Automatic payment receipts</p>
            </article>
            <article className="card">
              <figure>
                <img src={sendViaEmail} alt="email icon" />
              </figure>
              <p className="light">Send via email</p>
            </article>
            <article className="card">
              <figure>
                <img src={fullyIntegrated} alt="tree icon" />
              </figure>
              <p className="light">Fully integrated with other iQ Pro+ features</p>
            </article>
          </div>
        </section>
        <section className="">
          <div className="row">
            <div className="grid justify-center grid-cols-1">
              <p className="text-center large easy">
                Easily create, send and manage invoices -{' '}
                <span className="highlight-pink">all in one place</span>.
              </p>
            </div>
          </div>
        </section>
        <section className="">
          <div
            className="row"
            style={{
              borderRadius: '25px',
              backgroundImage: `url(${background})`,
              backgroundSize: '200% 200%',
              backgroundPosition: 'bottom center',
              backgroundRepeat: 'no-repeat'
            }}
          >
            <div className="p-4 mx-auto md:p-8 how">
              <h3 className="text-white large">How do Invoices work?</h3>
              <ul className="my-8 fancy-bullets white">
                <li>
                  <p className="text-white light">
                    Create customized Invoices in a few simple steps: choose or add a Customer,
                    enter Invoice details and payment terms, then add line items.
                  </p>
                </li>
                <li>
                  <p className="text-white light">
                    Give customers the flexibility to pay by Card or ACH and customize Invoices to
                    allow partial payments or require full payment.
                  </p>
                </li>
                <li>
                  <p className="text-white light">
                    Email Invoices directly to customers with a secure payment link, reducing your
                    PCI compliance burden and safeguarding customer data.
                  </p>
                </li>
                <li>
                  <p className="text-white light">
                    Offer a unique Payables URL for your customers, making it easy to manage and pay
                    multiple Invoices in one place.
                  </p>
                </li>
                <li>
                  <p className="text-white light">
                    Track payment statuses in real-time on the Invoices dashboard. View, edit, clone
                    or cancel invoices and manage associated Customers or Transactions.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="">
          <div className="row">
            <div className="my-8 callout">
              <p className="large">
                Stay <span className="highlight-pink">PCI compliant</span> by eliminating the need
                to store sensitive payment information in your system.
              </p>
            </div>
          </div>
        </section>
        <section className="">
          <div className="row customer-payables">
            <div className="grid justify-center grid-cols-1">
              <h2 className="large">Customer Payables</h2>
            </div>
            <div className="grid grid-cols-1">
              <p className="light medium">
                Offer customers an effortless way to manage and pay multiple invoices by generating
                a <span className="highlight-pink">unique Customer Payables URL</span>, providing
                customers a consolidated view of all their outstanding invoices.
              </p>
            </div>
          </div>
        </section>
        <section className="">
          <div
            className="row"
            style={{
              borderRadius: '25px',
              backgroundImage: `url(${background})`,
              backgroundSize: '200% 200%',
              backgroundPosition: 'bottom center',
              backgroundRepeat: 'no-repeat'
            }}
          >
            <div className="p-4 mx-auto md:p-8 how">
              <h3 className="text-white large">How does Customer Payables work?</h3>
              <ul className="my-8 fancy-bullets white">
                <li>
                  <div>
                    <p className="font-bold text-white">
                      Consolidated View of Outstanding Invoices
                    </p>
                    <p className="text-white light">
                      {' '}
                      This unique URL provides a Customer with a dedicated page showing all their
                      outstanding Invoices. This organized view makes it easy to see their total
                      balance and select invoices to pay.
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    <p className="font-bold text-white">Flexible Payment Options</p>
                    <p className="text-white light">
                      {' '}
                      Customers can select up to 10 Invoices per Transaction with options filtered
                      by the chosen payment method - Card or ACH - to show only eligible Invoices.
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    <p className="font-bold text-white">Simple Invoice Selection</p>
                    <p className="text-white light">
                      {' '}
                      Customers can manage their balances with flexibility and ease by selecting
                      Invoices and enabling full or partial payments.
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    <p className="font-bold text-white">Seamless Payment Submission</p>
                    <p className="text-white light">
                      {' '}
                      After selecting Invoices and entering payment details, Customers can submit
                      payments. Each Invoice is processed separately to ensure clear records of each
                      payment.
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    <p className="font-bold text-white">Comprehensive Confirmation and Receipts</p>
                    <p className="text-white light">
                      {' '}
                      Once they submit a payment, Customers see a confirmation page with the
                      statuses of all their Transactions. Customers also receive an email receipt
                      with payment details and Invoice breakdowns.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="">
          <div className="grid items-center grid-cols-1 row lg:grid-cols-2 invoicingRecurring">
            <figure className="hidden col-span-1 lg:block">
              <img
                src={RecurringInvoicing}
                alt="Screenshot for recurring billing with invoicing"
              ></img>
            </figure>
            <article className="col-span-1">
              <p className="text-center large lg:text-left">
                <span className="highlight-pink">Recurring Billing</span> utilizes Invoices to
                regularly bill your customers according to a custom schedule.
              </p>
              <Link
                to="/features/recurringBilling"
                className="button button-primary has-arrow has-arrow-white center-mobile"
              >
                Learn More
              </Link>
            </article>
            <figure className="col-span-1 lg:hidden">
              <img
                src={RecurringInvoicing}
                alt="Screenshot for recurring billing with invoicing"
              ></img>
            </figure>
          </div>
        </section>
        <GetStarted classGet="getStartedInvoicing" />
      </main>
      <Footer />
    </>
  );
}
