import Footer from '../Footer';
import Header from '../Header';
import MobileNavigation from '../MobileNavigation';
import background from '../img/softwarePartners.png';
import physicalTerminalImage from '../img/physicalTerminalImage.png';
import tablet from '../img/physicalTerminalTablet.png';
import PAXA35 from '../img/PAX A35.png';
import PAXA80 from '../img/PAX A80.png';
import PAXA77 from '../img/PAX A77.png';
import PAXA920 from '../img/PAX A920_A920 Pro.png';
import HeroFeatures from './HeroFeatures';
import GetStarted from './GetStarted';
import { useRef } from 'react';
import { useGSAP } from '@gsap/react';
import gsap from '@gsap/business';
import { ScrollTrigger } from '@gsap/business/ScrollTrigger';

gsap.registerPlugin(useGSAP);

gsap.registerPlugin(ScrollTrigger);

export default function PhysicalTerminals() {
  const container = useRef();
  useGSAP(
    () => {
      gsap.fromTo(
        '.hero-row',
        { y: 100, opacity: 0 },
        { y: 0, opacity: 1, duration: 1, ease: 'power3.inOut' }
      );
      gsap.fromTo(
        '.hero-secondary',
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'power3.inOut',
          scrollTrigger: {
            trigger: '.hero-secondary',
            start: 'top bottom-=300',
            end: 'top bottom+=100'
          }
        }
      );
      gsap.fromTo(
        '.compatible',
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'power3.inOut',
          scrollTrigger: {
            trigger: '.compatible',
            start: 'top bottom-=300',
            end: 'top bottom+=100'
          }
        }
      );
      gsap.fromTo(
        '.a920',
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'power3.inOut',
          scrollTrigger: {
            trigger: '.a920',
            start: 'top bottom-=300',
            end: 'top bottom+=100'
          }
        }
      );
      gsap.fromTo(
        '.a35',
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'power3.inOut',
          scrollTrigger: {
            trigger: '.a35',
            start: 'top bottom-=300',
            end: 'top bottom+=100'
          }
        }
      );
      gsap.fromTo(
        '.a77',
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'power3.inOut',
          scrollTrigger: {
            trigger: '.a77',
            start: 'top bottom-=300',
            end: 'top bottom+=100'
          }
        }
      );
      gsap.fromTo(
        '.a80',
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'power3.inOut',
          scrollTrigger: {
            trigger: '.a80',
            start: 'top bottom-=300',
            end: 'top bottom+=100'
          }
        }
      );
      gsap.fromTo(
        '.getStartedPhysical',
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'power3.inOut',
          scrollTrigger: {
            trigger: '.getStartedPhysical',
            start: 'top bottom-=300',
            end: 'top bottom+=100'
          }
        }
      );
    },
    { scope: container }
  );
  return (
    <>
      <main ref={container}>
        <Header />
        <MobileNavigation />

        <HeroFeatures
          title="PHYSICAL TERMINALS"
          primary={
            <>
              <span className="font-semibold text-[#C0F]">In-person</span> payments.
            </>
          }
          secondary="Process card transactions wherever your business takes you through iQ Pro+ compatible
              terminals."
          img={physicalTerminalImage}
          imgAlt="physical terminal with tapping a card and a mockup on how you see terminals in iQ Pro+"
        />

        <section className="hero-secondary">
          <div className="grid items-center grid-cols-1 row md:grid-cols-2">
            <figure className="hidden col-span-1 lg:block">
              <img src={tablet} alt="Status of physical terminals in a tablet mode." />
            </figure>
            <article className="hero-right">
              <h2 className="large">
                <span className="highlight-pink">Track</span> payment status
              </h2>
              <p className="light">
                Get real-time updates on your terminal transactions for you and your customer's
                peace of mind.
              </p>
            </article>
            <figure className="col-span-1 lg:hidden">
              <img src={tablet} alt="Status of physical terminals in a tablet mode." />
            </figure>
          </div>
        </section>

        <section className="">
          <div
            className="row"
            style={{
              borderRadius: '25px',
              backgroundImage: `url(${background})`,
              backgroundSize: '200% 220%',
              backgroundPosition: 'bottom center',
              backgroundRepeat: 'no-repeat'
            }}
          >
            <div className="p-4 mx-auto md:p-8">
              <h3 className="my-5 text-white large compatible">
                What physical terminals are compatible with iQ Pro+?
              </h3>

              {/* start pax PAX A920/ A920 Pro */}
              <div className="grid items-center grid-cols-1 py-6 md:grid-cols-3 a920">
                <figure className="hidden w-full md:block">
                  <img src={PAXA920} alt="Rendering of a PAX A920/ A920 Pro" />
                </figure>
                <div className="col-span-2">
                  <ul className="fancy-bullets white">
                    <li>
                      <p className="text-white xl">PAX A920/ A920 Pro</p>
                    </li>
                  </ul>
                  <p className="text-white">
                    Designed for all vertical markets and special projects, combining the full
                    features of an Android tablet with a powerful POS payment terminal in a sleek
                    and compact design.
                  </p>
                  <ul className="my-8 plain-bullets white">
                    <li>Powered by Android 8.1 or 10</li>
                    <li>Cortex A53 processor</li>
                    <li>5.5" capacitive touchscreen</li>
                    <li>4G + WiFi</li>
                    <li>Contactless + chip & PIN + magstripe</li>
                    <li>PIN on glass technology</li>
                    <li>5MP front + 8MP rear camera</li>
                    <li>5150mAh / 3.7V long-lasting battery</li>
                    <li>Fast-speed printer</li>
                    <li>PCI PTS 5.x (Android 8.1) or 6.x (Android 10) SRED</li>
                  </ul>
                </div>
                <figure className="w-full md:hidden">
                  <img src={PAXA920} alt="Rendering of a PAX A920/ A920 Pro" />
                </figure>
              </div>

              {/* start pax a35 */}
              <div className="grid items-center grid-cols-1 py-6 md:grid-cols-3 a35">
                <div className="col-span-2">
                  <ul className="fancy-bullets white">
                    <li>
                      <p className="text-white xl">PAX A35</p>
                    </li>
                  </ul>
                  <p className="text-white">
                    The world's first Android pinpad designed for multilane.
                  </p>
                  <ul className="my-8 plain-bullets white">
                    <li>Powered by Android 10</li>
                    <li>Power over Ethernet</li>
                    <li>4" capacitive touch screen</li>
                    <li>LAN + WiFi (2.4Ghz/5Ghz)</li>
                    <li>Chip &amp; PIN + contactless + Magnetic stripe</li>
                    <li>PIN-on-Glass also feasible</li>
                    <li>0.3MP or 5MP front camera</li>
                    <li>PCI PTS 6.x</li>
                  </ul>
                </div>
                <figure className="w-full">
                  <img src={PAXA35} alt="Rendering of a PAX A35" />
                </figure>
              </div>

              {/* start pax a77 */}
              <div className="grid items-center grid-cols-1 py-6 md:grid-cols-3 a80">
                <figure className="hidden w-full md:block">
                  <img src={PAXA80} alt="Rendering of a PAX A80" />
                </figure>
                <div className="col-span-2">
                  <ul className="fancy-bullets white">
                    <li>
                      <p className="text-white xl">PAX A80</p>
                    </li>
                  </ul>
                  <p className="text-white">
                    The perfect countertop solution for small and mid-sized businesses, combining
                    the potential of SmartPOS with the ease of use of traditional desktop terminals.
                  </p>
                  <ul className="my-8 plain-bullets white">
                    <li>Android 6.0, 7.1 or 10.0</li>
                    <li>Cortex A53 processor</li>
                    <li>4G/3G + WiFi + Ethernet + dial</li>
                    <li>Contactless + chip &amp; PIN + Magstripe</li>
                    <li>4-inch HD touch screen</li>
                    <li>Physical keypad</li>
                    <li>Camera</li>
                    <li>Printer</li>
                    <li>Optional Battery</li>
                    <li>PCI PTS 5.x or 6.x</li>
                  </ul>
                </div>
                <figure className="w-full md:hidden">
                  <img src={PAXA80} alt="Rendering of a PAX A80" />
                </figure>
              </div>

              {/* start pax a77 */}
              <div className="grid items-center grid-cols-1 py-6 md:grid-cols-3 a77">
                <div className="col-span-2">
                  <ul className="fancy-bullets white">
                    <li>
                      <p className="text-white xl">PAX A77 - Coming Soon</p>
                    </li>
                  </ul>
                  <p className="text-white">
                    Secure payment acceptance and professional barcode scanner in a stylish,
                    smartphone design.
                  </p>
                  <ul className="my-8 plain-bullets white">
                    <li>Android 10 OS</li>
                    <li>4G + WiFi</li>
                    <li>Contactless + Chip &amp; PIN + magstripe</li>
                    <li>5.5-inch color touchscreen</li>
                    <li>3.8V/5150mAh rechargeable battery</li>
                    <li>2MP front &amp; 5MP rear camera with option to upgrade</li>
                    <li>USB type-C charging port</li>
                    <li>PCI PTS 6.x. SRED</li>
                  </ul>
                </div>
                <figure className="w-full">
                  <img src={PAXA77} alt="Rendering of a PAX A77" />
                </figure>
              </div>
            </div>
          </div>
        </section>
        <GetStarted classGet="getStartedPhysical" />
        <Footer />
      </main>
    </>
  );
}
