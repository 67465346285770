import { Link } from 'react-router-dom';
export default function HeroFeatures(props) {
  return (
    <section className="">
      <div className="row hero-row">
        <article className="hero-left">
          <h2 className="text-left eyebrow text-[#4452A3] dark:text-[#A5ABD9]">{props.title}</h2>
          <h2 className="large">{props.primary}</h2>
          <p className="">{props.secondary}</p>
          <Link to="/bookDemo" className="button button-primary has-arrow has-arrow-white">
            Book a Demo
          </Link>
        </article>
        <figure className="cols-span-2">
          <img src={props.img} alt={props.imgAlt} />
        </figure>
      </div>
    </section>
  );
}
