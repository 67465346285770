import Footer from '../Footer';
import Header from '../Header';
import MobileNavigation from '../MobileNavigation';
import background from '../img/softwarePartners.png';
import mobileAppImage from '../img/MobileAppHeader.png';
import mobileTerminal from '../img/TerminalStandard.png';
import mobileCustomers from '../img/mobileCustomers.png';
import { Link } from 'react-router-dom';
import cc from '../img/icon-accept-card-and-ach.svg';
import customize from '../img/icon-customize-to-brand.svg';
import receipts from '../img/icon-globe.svg';
import fullyIntegrated from '../img/icon-card-scan.svg';
import { useGSAP } from '@gsap/react';
import gsap from '@gsap/business';
import { ScrollTrigger } from '@gsap/business/ScrollTrigger';
import { useRef, useState, useEffect } from 'react';
import quickPay from '../img/Quick Pay.png';
import apple from '../img/Apple.png';
import android from '../img/Android.png';
import androidLight from '../img/androidLight.png';
import appleLight from '../img/appleLight.png';
gsap.registerPlugin(useGSAP);

gsap.registerPlugin(ScrollTrigger);

export default function MobileApp() {
  const container = useRef();
  useGSAP(
    () => {
      gsap.fromTo(
        '.hero-row',
        { opacity: 0, y: 100 },
        { opacity: 1, y: 0, duration: 1, ease: 'power3.inOut' }
      );
      let cards = gsap.utils.toArray('.card');
      gsap.from(cards, {
        duration: 1,
        opacity: 0,
        y: 50,
        stagger: 0.1,
        ease: 'power3.inOut',
        scrollTrigger: {
          trigger: '.card',
          start: 'top bottom-=200',
          end: 'top bottom+=100'
        }
      });
      gsap.fromTo(
        '.hero-secondary',
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'power3.inOut',
          scrollTrigger: {
            trigger: '.hero-secondary',
            start: 'top bottom-=300',
            end: 'top bottom+=100'
          }
        }
      );
      gsap.fromTo(
        '.what',
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'power3.inOut',
          scrollTrigger: {
            trigger: '.what',
            start: 'top bottom-=200',
            end: 'top bottom+=100'
          }
        }
      );
      gsap.fromTo(
        '.terminalMobile',
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'power3.inOut',
          scrollTrigger: {
            trigger: '.terminalMobile',
            start: 'top bottom-=300',
            end: 'top bottom+=100'
          }
        }
      );
      gsap.fromTo(
        '.customersMobile',
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'power3.inOut',
          scrollTrigger: {
            trigger: '.customersMobile',
            start: 'top bottom-=300',
            end: 'top bottom+=100'
          }
        }
      );
      gsap.fromTo(
        '.getStartedMobile',
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          duration: 0.8,
          ease: 'power2.inOut',
          scrollTrigger: {
            trigger: '.getStartedMobile',
            start: 'top bottom-=100',
            end: 'top bottom+=100'
          }
        }
      );
    },
    { scope: container }
  );
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    setIsDarkMode(mediaQuery.matches);

    const listener = (event) => {
      setIsDarkMode(event.matches);
    };

    mediaQuery.addEventListener('change', listener);

    return () => {
      mediaQuery.removeEventListener('change', listener);
    };
  }, []);
  return (
    <>
      <main ref={container}>
        <Header />
        <MobileNavigation />
        <section className="">
          <div className="row hero-row">
            <article className="hero-left">
              <h2 className="text-left eyebrow text-[#4452A3]">MOBILE APP</h2>
              <h2 className="large">
                <span className="font-semibold text-[#C0F]">iQ Pro+</span> at your fingertips.
              </h2>
              <p className="">Process payments from your phone or tablet.</p>
              <Link to="/bookDemo" className="button button-primary has-arrow has-arrow-white">
                Book a Demo
              </Link>
            </article>
            <figure className="relative cols-span-2 mobile">
              {/* <img src={mobileAppImage} alt="background" />
              <img
                src={mobileHand}
                className="mobile-hand absolute top-[-103px]"
                alt="hand holding phone with iQ Pro+ app"
              /> */}
              <img src={mobileAppImage} alt="Mobile app" />
            </figure>
          </div>
        </section>
        <section className="">
          <div className="grid grid-cols-1 gap-4 row sm:grid-cols-2 lg:grid-cols-4">
            <article className="card">
              <figure>
                <img src={cc} alt="card icon" />
              </figure>
              <p className="light">Accept Card and ACH payments</p>
            </article>
            <article className="card">
              <figure>
                <img src={customize} alt="gear icon" />
              </figure>
              <p className="light">Available on iOS and Android</p>
            </article>
            <article className="card">
              <figure>
                <img src={receipts} alt="email icon" />
              </figure>
              <p className="light">Take secure mobile payments on the go</p>
            </article>
            <article className="card">
              <figure>
                <img src={fullyIntegrated} alt="tree icon" />
              </figure>
              <p className="light">Scan cards to seamlessly process transactions</p>
            </article>
          </div>
        </section>
        <section className="">
          <div
            className="row"
            style={{
              borderRadius: '25px',
              backgroundImage: `url(${background})`,
              backgroundSize: '200% 200%',
              backgroundPosition: 'bottom center',
              backgroundRepeat: 'no-repeat'
            }}
          >
            <div className="p-4 mx-auto md:p-8 what">
              <h3 className="text-white large">What is the iQ Pro+ Mobile App?</h3>
              <p className="text-white light">
                The iQ Pro+ Mobile App gives you the power to securely and efficiently collect
                payments from wherever you are.
              </p>
              <ul className="my-8 fancy-bullets white">
                <li>
                  <p className="text-white light">
                    Enjoy the same seamless experience on your phone, tablet or laptop thanks to the
                    iQ Pro+ mobile and web applications. Log in to your gateway on the Mobile App to
                    safely process transactions or quickly add, manage and charge customers.
                  </p>
                </li>
                <li>
                  <p className="text-white light">
                    Receive Level II and Level III rates on qualifying transactions.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="hero-secondary">
          <div className="grid items-center grid-cols-1 row md:grid-cols-2">
            <figure className="hidden col-span-1 lg:block">
              <img src={quickPay} alt="Quick Pay Screenshot" />
            </figure>
            <article className="hero-right">
              <h2 className="large">
                <span className="highlight-pink">Quick Pay</span>
              </h2>
              <p className="light">
                Use our Quick Pay feature for effortless payments when every second counts.
              </p>
              <Link
                to="/features/terminal"
                className="button button-primary has-arrow has-arrow-white center-mobile"
              >
                Learn More
              </Link>
            </article>
            <figure className="col-span-1 lg:hidden">
              <img src={quickPay} alt="Quick Pay Screenshot" />
            </figure>
          </div>
        </section>
        <section className="">
          <div className="grid items-center grid-cols-1 terminalMobile row lg:grid-cols-2">
            <article className="col-span-1">
              <p className="text-center large lg:text-left">
                Use <span className="highlight-pink">Standard Pay</span> when processing a
                transaction and you want to save a customer or when more details are needed.
              </p>
              <Link
                to="/features/terminal"
                className="button button-primary has-arrow has-arrow-white center-mobile"
              >
                Learn More
              </Link>
            </article>
            <figure className="col-span-1">
              <img src={mobileTerminal} alt="mockup of a mobile terminal" />
            </figure>
          </div>
        </section>
        <section className="">
          <div className="grid items-center grid-cols-1 customersMobile row lg:grid-cols-2">
            <figure className="hidden col-span-1 lg:block">
              <img src={mobileCustomers} alt="mockup of a view of customers on mobile" />
            </figure>
            <article className="inline-flex flex-col items-start justify-center gap-8">
              <p className="text-center large lg:text-left">
                Add, manage and charge <span className="highlight-pink">Customers</span> from your
                device.
              </p>
              <Link
                to="/features/customers"
                className="button button-primary has-arrow has-arrow-white center-mobile"
              >
                Learn More
              </Link>
            </article>
            <figure className="col-span-1 lg:hidden">
              <img src={mobileCustomers} alt="mockup of a view of customers on mobile" />
            </figure>
          </div>
        </section>

        <section>
          <div className="grid items-center justify-center grid-cols-1 row row-sm">
            <div>
            <p className="large">Already Using iQ Pro+?</p>
              <p>
                Great news! The iQ Pro+ Mobile app is automatically available to you. Just download
                or update the mobile app to get started.
              </p>
              <p className="large">How to download the app</p>
              <p className="mb-[2rem]">
              Use the links below or search for iQPro+ in the App Store or Google Play Store. Once downloaded, log in using the same credentials you use for the iQ Pro+ web app. It’s that simple!
              </p>
              <div className="grid items-center justify-center grid-cols-1 max-lg:gap-[25px] lg:grid-cols-2 mb-[25px]">
                <div className="flex flex-col gap-[25px]">
                  <img src={isDarkMode ? appleLight : apple} alt="apple icon" />
                  <p className="text-center !mb-0">For iOS Devices:</p>
                  <a
                    href="https://apps.apple.com/us/app/iq-pro/id6464065054"
                    target="_blank"
                    rel="noreferrer"
                    className="button button-primary center has-arrow has-arrow-white"
                  >
                    Download Here
                  </a>
                </div>
                <div className="flex flex-col gap-[25px]">
                  <img src={isDarkMode ? androidLight : android} alt="android icon" />
                  <p className="text-center !mb-0">For Android Devices:</p>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.basys.iqpro&hl=en_US"
                    target="_blank"
                    rel="noreferrer"
                    className="button button-primary center has-arrow has-arrow-white"
                  >
                    Download Here
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
